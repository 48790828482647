import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import FilledCircleIcon from '@material-ui/icons/Lens';
import OutlineCircleIcon from '@material-ui/icons/PanoramaFishEye';
import OpenDrawerIcon from '@material-ui/icons/ChevronRight';
import CloseDrawerIcon from '@material-ui/icons/ChevronLeft';
import Profile from './profile';
import _ from 'lodash';
import Alert from './alert';
import FetchingIndicator from './fetching-indicator';
import { FlexRow, FlexColumn } from '../layouts';
import { gnbColorPalette } from '../../gnb-theme';
import GNBCLogo from '../GNBCLogo';
import BuildUpdate from './BuildUpdate';
import ExpirationCheck from './ExpirationCheck';
import { isFetching } from '../../utility';
import LoadingSpinner from '../LoadingSpinner';
const drawerWidth = 225;

const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: gnbColorPalette.dark.primaryDark,
    height: '72px',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
    color: 'black',
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    paddingTop: 65,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: gnbColorPalette.dark.primaryDark,
    boxSizing: 'border-box',
    borderRightColor: 'transparent',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 50,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    //backgroundImage: 'linear-gradient(-225deg, #212126 0%, #2D2D34 59%)',
    background: gnbColorPalette.dark.level2,
    paddingTop: 70,
    flex: 1,
    overflowY: 'auto',
    overflowX: 'auto',
    //paddingBottom: 30,
  },
  flex: {
    flex: 1,
  },
  icon: {
    width: 30,
    height: 30,
    marginRight: 6,
    color: gnbColorPalette.dark.textLight,
  },
  iconSelected: {
    width: 30,
    height: 30,
    paddingLeft: 0,
    marginRight: 6,
    color: gnbColorPalette.dark.textLight,
  },
  iconSmall: {
    width: 10,
    height: 10,
    marginRight: 6,
  },
  iconSmallSelected: {
    width: 10,
    height: 10,
    paddingLeft: 0,
    marginRight: 6,
    color: 'black',
  },
  listItem: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  listItemButton: {
    borderColor: 'transparent',
  },
  listItemText: {
    paddingLeft: 3,
  },
  listItemTextSelected: {
    paddingLeft: 3,
  },
});

const defaultParamMap = {
  index: '',
  activeNavId: '',
  activeStepperDepth: 0,
  drawerOpen: false,
};

class NavFrameV2 extends React.Component {
  renderNavItems() {
    const { classes, nav } = this.props;
    let arr = this.props.navConfig.menu.map((item, idx) => {
      if (item.type === 'group') {
        return (
          <FlexColumn
            data-test-id={`nav-item-${item.nav}`}
            key={`nav-fram-item-${item.nav}-${item.label}`}
          >
            <FlexRow
              fullWidth
              style={{
                width: '95%',
                height: 40,
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
                backgroundColor:
                  nav.index === item.nav ? gnbColorPalette.dark.secondaryMedium : 'transparent',
                alignItems: 'center',
                paddingTop: 3,
              }}
            >
              <ListItem
                button
                onClick={() =>
                  this.handleClick.bind(this)(
                    item.nav,
                    item.navId,
                    nav.drawerOpen,
                    0,
                    item.parameters || {}
                  )
                }
                disableGutters={true}
                divider={false}
                classes={{
                  root: classes.listItem,
                  button: classes.listItemButton,
                }}
              >
                <FlexRow>
                  <ListItemIcon
                    className={
                      this.props.nav.index === item.nav ? classes.iconSelected : classes.icon
                    }
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    className={classes.listItemText}
                    disableTypography={true}
                    primary={<Typography variant="subtitle1">{item.label}</Typography>}
                  />
                </FlexRow>
              </ListItem>
            </FlexRow>
            {nav.drawerOpen ? this.renderGroupChildren(item) : ''}
            {/*<Divider style={{width: '100%'}}/>*/}
          </FlexColumn>
        );
      } else if (item.type === 'spacer') {
        return (
          <FlexColumn
            data-test-id={`nav-item-${item.nav}`}
            key={`nav-fram-item-${item.nav}-${idx}`}
          >
            <FlexRow
              fullWidth
              style={{
                width: '95%',
                height: 40,
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
                backgroundColor: 'transparent',
                alignItems: 'center',
                paddingTop: 3,
              }}
            />
          </FlexColumn>
        );
      } else {
        let indent = 0;
        return (
          <FlexRow style={{ paddingLeft: indent }} key={`nav-fram-item-${item.nav}-${item.label}`}>
            <ListItem
              button
              onClick={() =>
                this.handleClick.bind(this)(
                  item.nav,
                  item.navId,
                  nav.drawerOpen,
                  0,
                  item.parameters
                )
              }
              disableGutters={true}
              divider={false}
              className={classes.listItem}
              classes={{
                root: classes.listItem,
                button: classes.listItemButton,
                divider: classes.listItemDivider,
              }}
            >
              <ListItemIcon
                className={this.props.nav.index === item.nav ? classes.iconSelected : classes.icon}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                disableTypography={true}
                primary={<Typography variant="subtitle1">{item.label}</Typography>}
              />
            </ListItem>
          </FlexRow>
        );
      }
    }, this);

    arr.push(
      <ListItem
        button
        onClick={
          nav.drawerOpen ? this.handleDrawerClose.bind(this) : this.handleDrawerOpen.bind(this)
        }
        disableGutters={true}
        className={classes.listItem}
        divider={false}
        key="open-drawer-button"
      >
        {nav.drawerOpen ? (
          <FlexRow fullWidth right>
            <ListItemIcon>
              {nav.drawerOpen ? (
                <CloseDrawerIcon color="primary" />
              ) : (
                <OpenDrawerIcon color="primary" />
              )}
            </ListItemIcon>
          </FlexRow>
        ) : (
          <FlexRow fullWidth>
            <ListItemIcon>
              {nav.drawerOpen ? (
                <CloseDrawerIcon color="primary" />
              ) : (
                <OpenDrawerIcon color="primary" />
              )}
            </ListItemIcon>
          </FlexRow>
        )}
      </ListItem>
    );

    return arr;
  }

  renderGroupChildren(group) {
    const { classes, activeStepperDepth, nav } = this.props;
    return group.children.map((item, index) => {
      if (item.type === 'child') {
        return (
          <FlexRow
            fullWidth
            key={`nav-fram-item-${item.nav}-${item.label}`}
            style={{
              width: '95%',
              height: 40,
              borderTopRightRadius: 20,
              borderBottomRightRadius: 20,
              backgroundColor:
                nav.index === item.nav ? gnbColorPalette.dark.secondaryMedium : 'transparent',
              alignItems: 'center',
              paddingTop: 3,
            }}
          >
            <ListItem
              button
              onClick={() =>
                this.handleClick.bind(this)(
                  item.nav,
                  item.navId,
                  nav.drawerOpen,
                  0,
                  item.parameters
                )
              }
              disableGutters={true}
              className={classes.listItem}
            >
              <ListItemText
                className={classes.listItemText}
                primary={<div style={{ paddingLeft: 50 }}>{item.label}</div>}
              />
            </ListItem>
          </FlexRow>
        );
      } else {
        return (
          <FlexRow
            style={{ paddingLeft: 30, position: 'relative' }}
            key={`nav-fram-item-${item.nav}-${item.label}`}
          >
            <ListItem
              button
              onClick={() =>
                this.handleClick.bind(this)(
                  item.nav,
                  item.navId,
                  nav.drawerOpen,
                  index,
                  0,
                  item.parameters
                )
              }
              disableGutters={true}
              className={classes.listItem}
            >
              {index <= activeStepperDepth ? (
                <div
                  style={{
                    position: 'absolute',
                    top: index === 0 ? -8 : -23,
                    left: 0,
                    width: 10,
                    height: index === 0 ? 32 : 47,
                    borderLeft: '1px solid black',
                    borderBottom: '1px solid black',
                  }}
                />
              ) : (
                ''
              )}
              <ListItemIcon
                className={
                  index <= activeStepperDepth ? classes.iconSmallSelected : classes.iconSmall
                }
              >
                {index <= activeStepperDepth ? (
                  <FilledCircleIcon style={{ fontSize: 20 }} />
                ) : (
                  <OutlineCircleIcon style={{ fontSize: 20 }} />
                )}
              </ListItemIcon>
              <ListItemText className={classes.listItemText} primary={item.label} />
            </ListItem>
          </FlexRow>
        );
      }
    }, this);
  }

  render() {
    const { classes, height, updates, nav, saving } = this.props;
    const fetching = isFetching(updates);
    return (
      <div className={classes.root} style={{ height: height }}>
        <AppBar position="fixed" className={classNames(classes.appBar)}>
          <Toolbar style={{ height: '72px' }}>
            <FlexRow fullWidth style={{ alignItems: 'center' }}>
              <FlexRow
                style={{
                  width: '100%',
                  paddingTop: '16px',
                  paddingBottom: '16px',
                  alignItems: 'center',
                }}
              >
                <GNBCLogo height={40} width={441} />
              </FlexRow>
              <FlexRow right style={{ width: '70%', alignItems: 'center' }}>
                <FetchingIndicator updates={updates} />
                <Profile
                  logoutUser={this.props.logoutUser}
                  goToAdmin={this.props.goToAdmin}
                  goToSettings={this.props.goToSettings}
                  isProjectsAdmin={this.props.isProjectsAdmin}
                  user={this.props.user}
                />
              </FlexRow>
            </FlexRow>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(classes.drawerPaper, !nav.drawerOpen && classes.drawerPaperClose),
          }}
          open={nav.drawerOpen}
        >
          <Divider />
          <List>{this.renderNavItems()}</List>

          {nav.drawerOpen && (
            <div style={{ position: 'absolute', bottom: 10, left: 0 }}>
              <Typography variant="caption" style={{ paddingLeft: 10, marginTop: 30 }}>
                {this.props.buildIdentifier ? `Version ${this.props.buildIdentifier}` : ''}
              </Typography>
            </div>
          )}
        </Drawer>
        <main className={classes.content}>{this.props.children}</main>
        <Alert
          open={this.props.alerts.show}
          msg={this.props.alerts.msg}
          type={this.props.alerts.type}
          onClose={this.handleAlertClosed.bind(this)}
        />
        <ExpirationCheck authenticate={this.props.user} onLogin={this.props.logoutUser} />
        {process.env.NODE_ENV === 'production' && this.props.updateSoftwareVersion && (
          <BuildUpdate />
        )}
        {saving || fetching ? <LoadingSpinner /> : ''}
      </div>
    );
  }

  handleDrawerOpen() {
    let copy = copyObject(this.props.nav);
    copy.drawerOpen = true;
    this.props.setNav(copy);
    this.props.onChange(true);
  }

  handleDrawerClose() {
    let copy = copyObject(this.props.nav);
    copy.drawerOpen = false;
    this.props.setNav(copy);
    this.props.onChange(false);
  }

  handleAlertClosed() {
    this.props.hideAlert();
  }

  handleClick(name, id, drawerOpen, depth = 0, parameters = {}) {
    let nav = copyObject(defaultParamMap);
    nav.index = name;
    nav.activeNavId = id;
    nav.activeStepperDepth = depth;
    nav.drawerOpen = drawerOpen;
    if (!_.isEmpty(parameters)) {
      for (let key in parameters) {
        if (parameters.hasOwnProperty(key)) {
          nav[key] = parameters[key];
        }
      }
    }

    this.setState({
      expanded: false,
      size: 50,
    });

    this.props.setNav(nav);
  }
}

/*
                           goToAdmin={this.props.goToAdmin}
                           goToSettings={this.props.goToSettings}
                           isProjectsAdmin={this.props.isProjectsAdmin}
 */

NavFrameV2.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  nav: PropTypes.object.isRequired,
  navConfig: PropTypes.object.isRequired,
  alerts: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  setNav: PropTypes.func.isRequired,
  hideAlert: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  goToAdmin: PropTypes.func,
  goToSettings: PropTypes.func,
  isProjectsAdmin: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  setSearch: PropTypes.func,
  subTitle: PropTypes.string,
  updateSoftwareVersion: PropTypes.bool,
};

NavFrameV2.defaultProps = {
  updateSoftwareVersion: false,
};

export default withStyles(styles, { withTheme: true })(NavFrameV2);

function copyObject(a) {
  return JSON.parse(JSON.stringify(a));
}
